import { Field, FieldArray, Form, Formik } from "formik";
import style from "./index.module.scss";
import InputLayout from "../../../atoms/InputLayout";
import * as Yup from "yup";
import PageCard from "../../../atoms/PageCard";
import ImageSelector from "../../../atoms/ImageSelector";
import { replaceSpacesInImageUrl } from "../../../../utils/Helpers/replaceSpaceInImage";
import { useEffect, useRef, useState } from "react";
import SelectDropdown from "../../../atoms/Dropdown";
import { VideoItemType } from "../../../../helpers/staticData";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confirmationStyles } from "../../../../assets/styles/toast";
import {
  patchData,
  patchFormData,
  postData,
  postFormData,
} from "../../../../services";
import { useParams } from "react-router-dom";
import { countrybyCurrency } from "../../../../utils/Helpers/countryByCurrency";
import Error from "../../../atoms/Error";
import AddCollection from "../Addcollection";
import CustomTimePicker from "../../../atoms/TimePicker";
import { debounce } from "lodash";
import LoadingIndicator from "./LoadingIndicator";
import { itemDataDropdown } from "../../../../utils/Helpers/itemtype";

const AddPreview = ({
  hoverItem,
  setHoverItem,
  setChooseCoordinates,
  getDesctiptionDetail,
  getVideopreviewDataById,
  getHighLightDetail,
  getCartDetail,
  getCatalogueDetails,
  videoEditTable,
  activeTab,
  setActiveTab,
  playerTime,
  videoLink,
  setModalState,
  setAfterEditState,
  setEditPosition,
  setEditModalState,
  CartData,
  getCollectionDetails,
  collectionData,
}) => {
  const [addItemType, setAddItemType] = useState(activeTab);
  const [cookies] = useCookies(["t", "auid"]);
  const { id } = useParams();
  const [maxTime, setMaxTime] = useState(false); // Initial maximum time set to 23:59
  const [duration, setDuration] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // console.log("CartData", CartData);

  const [isScraping, setIsScraping] = useState(false);
  const [formValues, setFormValues] = useState({
    product_description: "",
    price: "",
    link: "",
    currency: "",
    // ... other fields
  });

  const handleProductLinkChange = async (e, setFieldValue) => {
    const newLink = e.target.value;

    console.log("Link pasted:", newLink); // Debugging log

    // Set the field value in Formik
    setFieldValue("link", newLink);

    // Debounced scraping function
    debouncedFetchScrapedData(newLink, setFieldValue);
  };

  const fetchImageAsFile = async (imageUrl, fileName) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error("Failed to fetch image and convert to file:", error);
      return null;
    }
  };



  // Debounced function to fetch data
  const debouncedFetchScrapedData = debounce(async (link, setFieldValue) => {
    setIsScraping(true); // Start loading indicator

    const scrapedData = await fetchScrapedData(link);

    console.log("Scraped data:", scrapedData); // Debugging log

    setIsScraping(false); // Stop loading indicator

    if (scrapedData) {

      if (scrapedData.image) {
        console.log("Scraped image URL:", scrapedData.image);

        const fileName = 'product-image.png';
        const imageFile = await fetchImageAsFile(scrapedData.image, fileName);

        if (imageFile) {
        console.log("Successfully fetched image and converted to file:", imageFile);
        setFieldValue("profile_photo", imageFile);
    
    // Force a re-render by updating a state variable
    setFormValues((prevValues) => ({
      ...prevValues,
      profile_photo: URL.createObjectURL(imageFile),
    }));
  } else {
    console.error("Failed to fetch image and convert to file");
  }

  } else {
    console.warn("No image URL found in scraped data");
  }

      // Update the form fields with the scraped data
      setFieldValue("product_description", scrapedData.name || "");
      setFieldValue("price", scrapedData.price || "");
      setFieldValue("currency", scrapedData.currency || "");

      // Check for missing fields and warn the user
      let missingFields = [];
      if (!scrapedData.name || scrapedData.name === "N/A") missingFields.push("description");
      if (!scrapedData.price || scrapedData.price === "N/A") missingFields.push("price");
      if (!scrapedData.currency || scrapedData.currency === "Unknown Currency") missingFields.push("currency");
      if (!scrapedData.image || scrapedData.image === "N/A") missingFields.push("image");

      const CustomToast = ({ missingFields }) => (
        <div>
          <span>Warning: Unable to fetch {missingFields.map((field, index) => (
            <strong key={index}>{field}</strong>
          )).reduce((prev, curr) => [prev, ', ', curr])}. Please check the product link or fill them manually.</span>
        </div>
      );
      if (missingFields.length > 0) {
          toast(
            <CustomToast missingFields={missingFields} />, 
            {
              duration: 10000,
              style: { 
                  backgroundColor: '#fff3cd',
                  color: '#856404',          
                  padding: '16px',           
                  borderRadius: '8px',       
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
              },
              icon: '⚠️',                     
          });
      }
    }
  }, 1000); // Debounce delay of 1 second

  const fetchScrapedData = async (productLink) => {
    try {
      const response = await fetch(process.env.REACT_APP_PYTHON_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: productLink }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch scraped data");
      }

      const data = await response.json();
      console.log("Scraped data:", data); // Debugging log
      return data;
    } catch (error) {
      console.error("Error during scraping:", error);
      return null;
    }
  };


  // const existingCollectionData = CartData?.map((item) => {
  //   return {
  //     label: item?.name,
  //     value: item?.id,
  //   };
  // });

  // Function to handle the change in the end time input
  const handleEndTimeChange = (time) => {
  function calculateTotalSeconds(timeString) {
    var parts = timeString.split(":");
    var secondsParts = parts[2].split(".");

    // Convert hours, minutes, seconds, and deciseconds into integers
    var hours = parseInt(parts[0], 10);
    var minutes = parseInt(parts[1], 10);
    var seconds = parseInt(secondsParts[0], 10);
    var deciseconds = parseInt(secondsParts[1], 10) / 10;

    // Calculate total seconds including deciseconds
    var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds;
    return totalSeconds;
  }
  const Time = calculateTotalSeconds(time);
  const durationTime = calculateTotalSeconds(duration);
  if (Time > durationTime) {
    setMaxTime(true);
  } else {
    setMaxTime(false);
  }
};

  const validationSchema = Yup.object({
    product_name: Yup.string()
      .required("Product Name is required")
      .max(100, "Product Name must be at most 100 characters"),
    product_description: Yup.string(),
    // profile_photo: Yup.mixed().required(),
      start_time: Yup.string()
      .required("Start time is required")
      .matches(
        /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]\.\d$/,
        "Invalid time format. Please use HH:MM:SS.d format"
      ),
    end_time: Yup.string()
      .required("End time is required")
      .matches(
        /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]\.\d$/,
        "Invalid time format. Please use HH:MM:SS.d format"
      )

      .test(
        "is-greater",
        "End time must be greater than start time",
        function (value) {
          const { start_time } = this.parent;
          if (!start_time || !value) return true; // if either start_time or end_time is not provided, skip validation
          return (
            new Date(`2000-01-01T${value}`) >
            new Date(`2000-01-01T${start_time}`)
          );
        }
      ),
    currency: Yup.string().test(
      "currency-and-price",
      "Currency is required,when price have value",
      function (value) {
        const { currency, price } = this.parent;
        if (price && !currency) {
          return false;
        }
        return true;
      }
    ),
    price: Yup.string()
      .nullable()
      .test(
        "currency-and-price",
        "Price is required,when currency is selected",
        function (value) {
          const { currency, price } = this.parent;
          if (!price && currency) {
            return false;
          }
          return true;
        }
      ),
    // link: Yup.string().matches(
    //   /^https:\/\//,
    //   'Website URL must start with "https://"'
    // ),
  });

  function formatTime(seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = Math.floor(seconds % 60);
    var deciseconds = Math.floor((seconds % 1) * 10);

    // Add leading zeros if necessary
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    remainingSeconds =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

    return `${hours}:${minutes}:${remainingSeconds}.${deciseconds}`;
  }

  useEffect(() => {
    const getDuration = async (videoUrl) => {
      try {
        const video = document.createElement("video");
        video.src = videoUrl;

        const loadedMetadataPromise = new Promise((resolve) => {
          video.onloadedmetadata = () => {
            resolve();
          };
        });

        video.load(); // Start loading the video metadata

        await loadedMetadataPromise; // Wait for the metadata to be loaded

        const totalSeconds = Math.floor(video.duration);
        const hours = Math.floor(totalSeconds / 3600); // Calculate hours
        const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
        const seconds = totalSeconds % 60; // Calculate seconds

        const formattedDuration = `${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

        return formattedDuration; // Return the formatted duration in HH:MM:SS format
      } catch (error) {
        console.error("Error loading video duration:", error);
        return null; // Return null if there's an error
      }
    };

    const calculateDuration = async () => {
      try {
        const duration = await getDuration(videoLink);
        setDuration(duration);
      } catch (error) {
        console.error("Error calculating duration:", error);
        setDuration(null); // Handle error by setting duration to null
      }
    };

    calculateDuration(); // Call the function immediately when the component mounts
  }, [videoLink]); // Include videoLink in the dependency array to recalculate duration when it changes

  const initialValues = {
    profile_photo: videoEditTable?.photo || null,
    product_name: videoEditTable?.name || "",
    product_description: videoEditTable?.description || "",
    start_time:
      playerTime !== ""
        ? formatTime(playerTime)
        : videoEditTable?.start_time || "",
    product_cta: videoEditTable?.product_cta || "",
    end_time: videoEditTable?.end_time || "",
    price: videoEditTable?.price || "",
    link: videoEditTable?.link || "",
    currency: videoEditTable?.currency || "",
    positionx: hoverItem?.positionx,
    positiony: hoverItem?.positiony,
    item_type: videoEditTable?.item_type || "",
    // dropDownType: "Description",
  };

  /* -------Regarding HighLights Services----------*/

  const handleAddHighLightItem = async (values, { resetForm }) => {
    // console.log("varun2");
    try {
      setIsLoading(true);
      if (videoEditTable?.id) {
        const res = await patchData({
          endpoint: "VideoProduct/editProductHighlight",
          data: {
            id: videoEditTable?.id,
            name: values?.product_name,
            link: values?.link,
            start_time: values.start_time,
            end_time: values.end_time,
            x_coordinate: hoverItem?.positionx,
            y_coordinate: hoverItem?.positiony,
          },
          params: {},
          token: cookies.t,
        });
        if (res) {
          setIsLoading(false);
          toast.success("The Highlight Was Added", {
            duration: 2000,
            style: confirmationStyles,
          });
          getHighLightDetail();
          resetForm();
          setChooseCoordinates(false);
          setHoverItem(() => ({
            positiony: 0,
            positionx: 0,
          }));
          setModalState &&
            setModalState(() => ({ isModalOpen: false, data: {}, name: "" }));
          setEditModalState &&
            setEditModalState(() => ({
              isModalOpen: false,
              data: {},
              name: "",
            }));
          getVideopreviewDataById();
        }
      } else {
        const res = await postData({
          endpoint: "VideoProduct/addProductHighlight",
          data: {
            name: values?.product_name,
            video_id: id,
            link: values?.link,
            start_time: values.start_time,
            end_time: values.end_time,
            x_coordinate: hoverItem?.positionx,
            y_coordinate: hoverItem?.positiony,
            user_id: cookies.auid,
          },
          params: {},
          token: cookies.t,
        });
        if (res) {
          setIsLoading(false);
          toast.success("The Highlight Was Added", {
            duration: 2000,
            style: confirmationStyles,
          });
          setEditPosition &&
            setEditPosition(() => ({
              isModalOpen: false,
              data: {},
              name: "",
            }));
          setModalState(() => ({
            isModalOpen: false,
          }));
          getHighLightDetail();
          resetForm();
          setChooseCoordinates(false);
          setHoverItem(() => ({
            positiony: 0,
            positionx: 0,
          }));
          getVideopreviewDataById();
        }

        console.log(res);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  /* -----------------------------------------*/

  /* -------Regarding Cart Services----------*/

  const handleAddCartItem = async (values, { resetForm }) => {
    console.log("varun2", values);

    try {
      setIsLoading(true);
      if (videoEditTable?.id) {
        const res = await patchFormData(
          "VideoProduct/editProductCard",
          {
            id: videoEditTable?.id,
            name: values?.product_name,
            description: values?.product_description,
            video_id: id,
            start_time: values?.start_time,
            end_time: values?.end_time,
            price: values?.price,
            link: values?.link,
            product_cta: values?.product_cta,
            x_coordinate: hoverItem?.positionx,
            y_coordinate: hoverItem?.positiony,
            photo: values?.profile_photo,
            currency: values?.currency,
            remove_photo: values?.remove_photo,
            item_type: values?.item_type,
          },
          {}, // Empty params object
          cookies.t // Assuming cookies.t contains the token
        );
        console.log("Submitting Form Data:", values);

        if (res) {
          console.log("API Response:", res);
          setIsLoading(false);
          toast.success("The Cart Was Added", {
            duration: 2000,
            style: confirmationStyles,
          });
          setModalState &&
            setModalState(() => ({ isModalOpen: false, data: {}, name: "" }));
          setEditModalState &&
            setEditModalState(() => ({
              isModalOpen: false,
              data: {},
              name: "",
            }));
          getCartDetail();
          resetForm();
          setChooseCoordinates(false);
          setHoverItem(() => ({
            positiony: 0,
            positionx: 0,
          }));
          getVideopreviewDataById();
        }
      } else {
        const res = await postFormData(
          "VideoProduct/addProductCard", // Corrected syntax
          {
            name: values?.product_name,
            photo: values?.profile_photo,
            description: values?.product_description,
            link: values?.link,
            product_cta: values?.product_cta,
            video_id: id,
            price: values?.price,
            start_time: values?.start_time,
            end_time: values?.end_time,
            x_coordinate: hoverItem?.positionx,
            y_coordinate: hoverItem?.positiony,
            user_id: cookies.auid,
            currency: values?.currency,
            item_type: values?.item_type,
          },
          {}, // Empty params object
          cookies.t // Assuming cookies.t contains the token
        );
        console.log("Submitting Form Data:", values);
        if (res) {
          console.log("API Response:", res);
          setIsLoading(false);
          toast.success("The Cart Was Added", {
            duration: 2000,
            style: confirmationStyles,
          });
          setEditPosition &&
            setEditPosition(() => ({ isModalOpen: false, data: {}, name: "" }));
          getCartDetail();
          resetForm();
          setModalState(() => ({
            isModalOpen: false,
          }));
          setChooseCoordinates(false);
          setHoverItem(() => ({
            positiony: 0,
            positionx: 0,
          }));
          getVideopreviewDataById();
        }
        console.log(res);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  /* -----------------------------------------*/

  /* -------Regarding Description Services----------*/

  const handleAddDescriptionItem = async (values, { resetForm }) => {
    console.log("varun2");
    try {
      setIsLoading(true);
      if (videoEditTable?.id) {
        const res = await patchFormData(
          "VideoProduct/editProductDescription",
          {
            id: videoEditTable?.id,
            name: values?.product_name,
            description: values?.product_description,
            link: values?.link,
            start_time: values?.start_time,
            end_time: values?.end_time,
            x_coordinate: hoverItem?.positionx,
            y_coordinate: hoverItem?.positiony,
            photo: values?.profile_photo,
            remove_photo: values?.remove_photo,
          },
          {},
          cookies.t
        );
        if (res) {
          setIsLoading(false);
          toast.success("The Description Was Updated", {
            duration: 2000,
            style: confirmationStyles,
          });
          setModalState &&
            setModalState(() => ({ isModalOpen: false, data: {}, name: "" }));
          setEditModalState &&
            setEditModalState(() => ({
              isModalOpen: false,
              data: {},
              name: "",
            }));
          resetForm();
          getDesctiptionDetail();
          setChooseCoordinates(false);
          setHoverItem(() => ({
            positiony: 0,
            positionx: 0,
          }));
          getVideopreviewDataById();
        }
      } else {
        const res = await postFormData(
          "VideoProduct/addProductDescription", // Corrected syntax
          {
            name: values?.product_name,
            photo: values?.profile_photo,
            description: values?.product_description,
            video_id: id,
            link: values?.link,
            start_time: values?.start_time,
            end_time: values?.end_time,
            x_coordinate: hoverItem?.positionx,
            y_coordinate: hoverItem?.positiony,
            user_id: cookies.auid,
          },
          {}, // Empty params object
          cookies.t // Assuming cookies.t contains the token
        );
        if (res) {
          setIsLoading(false);
          toast.success("The Description Was Added", {
            duration: 2000,
            style: confirmationStyles,
          });
          setModalState(() => ({
            isModalOpen: false,
          }));
          setEditPosition &&
            setEditPosition(() => ({
              isModalOpen: false,
              data: {},
              name: "",
            }));
          resetForm();
          getDesctiptionDetail();
          setChooseCoordinates(false);
          setHoverItem(() => ({
            positiony: 0,
            positionx: 0,
          }));
          getVideopreviewDataById();
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  // useEffect(() => {
  //   const removeBtn = document.getElementById("ImageRemoveBtn");
  //   console.log(removeBtn);
  //   if (removeBtn) removeBtn.onClick();
  // }, []);

  // console.log("remove", document.getElementById("ImageRemoveBtn"));

  /* -----------------------------------------*/

  const handleSubmit = (values, { resetForm }) => {
    console.log("vals", values);
    console.log("varun0", values.dropDownType);
    if (addItemType === "HighLight")
      handleAddHighLightItem(values, { resetForm });
    else if (addItemType === "Cart") handleAddCartItem(values, { resetForm });
    else if (addItemType === "Description")
      handleAddDescriptionItem(values, { resetForm });
  };

  useEffect(() => {
    setAddItemType(activeTab);
  }, [activeTab]);

  const handleCancelButton = () => {
    setChooseCoordinates(false);
    setModalState(() => ({
      isModalOpen: false,
    }));
    setHoverItem(() => ({
      positionx: 0,
      positiony: 0,
    }));
    setModalState &&
      setModalState(() => ({ isModalOpen: false, data: {}, name: "" }));
    setEditModalState &&
      setEditModalState(() => ({ isModalOpen: false, data: {}, name: "" }));
  };

  return (
    <div className={style.AddPreviewWrapper}>
      <div>
        <label>Widget Type</label>
        <SelectDropdown
          data={VideoItemType}
          onChange={(option) => {
            setAddItemType(option);
            setActiveTab(option);
            // setFieldValue("dropDownType", option);
          }}
          isDisabled={videoEditTable?.id}
          placeholder="Item Type"
          // isClearable={true}
          selectedValue={
            activeTab === "Description"
              ? "Description"
              : activeTab === "Cart"
              ? "Cart"
              : activeTab === "HighLight"
              ? "HighLight"
              : activeTab === "Collection"
              ? "Collection"
              : null
          }
        />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        resetForm
        key={videoEditTable?.id}
      >
        {({
          dirty,
          isValid,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          setTouched,
        }) => (
          <Form>
            <div className={style.video_main_preview_container}>
              <div>
                {(addItemType === "Description" || addItemType === "Cart") && (
                  <>
                    <label className="mt-3">Product Image</label>
                    <ImageSelector
                      handleFile={(file) => {
                        console.log("option", file);
                        setFieldValue("profile_photo", file);
                        setFieldValue("remove_photo", false);
                      }}
                      selectedImage={values?.profile_photo ? (typeof values?.profile_photo === "string" ?                     replaceSpacesInImageUrl(values.profile_photo) : URL.createObjectURL(values.profile_photo)) : null}
                      onRemove={() => {
                        setFieldValue("remove_photo", true);
                        setFieldValue("profile_photo", null); // Remove the image from the state
                      }}
                    />
                  </>
                )}
                <div className="row g-3 mt-2">
                  {(addItemType === "Description" ||
                    addItemType === "Cart" ||
                    addItemType === "HighLight") && (
                    <>
                      <InputLayout>
                        <label htmlFor="url">Product Name</label>
                        <Field
                          type="text"
                          id="product_name"
                          name="product_name"
                          placeholder="Product Name"
                        />
                        {touched.product_name && errors.product_name && (
                          <Error error={errors.product_name} />
                        )}
                      </InputLayout>
                    </>
                  )}
                  {(addItemType === "Description" ||
                    addItemType === "Cart") && (
                    <InputLayout>
                      <label htmlFor="url">Product Description</label>
                      <Field
                        type="text"
                        id="product_description"
                        name="product_description"
                        placeholder="Product Description"
                      />
                    </InputLayout>
                  )}
                  {(addItemType === "Description" ||
                    addItemType === "Cart" ||
                    addItemType === "HighLight") && (
                    <InputLayout>
                      <label htmlFor="url">Product Link</label>
                      <Field
                        type="text"
                        id="link"
                        name="link"
                        placeholder="Product Link"
                        onChange={(e) => handleProductLinkChange(e, setFieldValue)}
                        value={values.link}
                        disabled={isScraping}
                      />
                      {isScraping && (
                        <LoadingIndicator
                          message="Hang on tight! We're fetching the data."
                          duration={5000} // 5 seconds
                        />
                      )}
                    </InputLayout>
                  )}
                  {addItemType === "Cart" && (
                    <>
                      <div className="row g-3 mt-2">
                        <InputLayout>
                          <label htmlFor="url">Currency</label>
                          <SelectDropdown
                            data={countrybyCurrency}
                            onChange={(option) => {
                              console.log("currency", countrybyCurrency);
                              setFieldValue("currency", option);
                              console.log("currency selected", option);
                              if (option === null)
                                setFieldValue("currency", "");
                            }}
                            placeholder="Currency"
                            selectedValue={values?.currency}
                            isClearableBool={true}
                          />
                          {errors.currency && <Error error={errors.currency} />}
                        </InputLayout>


                        <InputLayout>
                          <label htmlFor="url">Product Price</label>
                          <Field
                            type="text"
                            id="price"
                            name="price"
                            placeholder="Product Price"
                          />
                          {errors.price && <Error error={errors.price} />}
                        </InputLayout>
                      </div>
                      {/* <InputLayout>
                        <label htmlFor="url">Product Link</label>
                        <Field
                          type="text"
                          id="link"
                          name="link"
                          placeholder="Product Link"
                        />
                      </InputLayout> */}
                      <InputLayout>
                        <Field
                          type="text"
                          id="product_cta"
                          name="product_cta"
                          placeholder="Product CTA"
                        />
                      </InputLayout>


                    </>
                  )}
                </div>

                <div className="row g-3 mt-2">
                  {(addItemType === "Cart") && (
                    <>
                      <InputLayout>
                        <label htmlFor="url">Item Type</label>
                        <SelectDropdown
                          data={itemDataDropdown}
                          onChange={(type) => {
                            console.log("Item Type: ", itemDataDropdown);
                            setFieldValue("item_type", type);
                            console.log("Item Selected: ", type)
                            if (type === null)
                              setFieldValue("item_type", "");
                          }}
                          placeholder="Item Type"
                          selectedValue={values?.item_type}
                          isClearableBool={true}
                        />
                        {errors.item_type && <Error error={errors.item_type} />}
                      </InputLayout>
                    </>
                  )}
                </div>

                {(addItemType === "Description" ||
                  addItemType === "Cart" ||
                  addItemType === "HighLight") && (
                  <>
                    <div className="row g-3 mt-2">
                      <InputLayout style={{ width: "40%" }}>
                        <label htmlFor="url">Start Time</label>
                        <CustomTimePicker
                          placeholder="Start Time"
                          onChange={(time) => {
                            setFieldValue("start_time", time);
                          }}
                          selectedTime={values?.start_time}
                        />
                      </InputLayout>
                      <InputLayout style={{ width: "40%" }}>
                        <label htmlFor="url">End Time</label>
                        <CustomTimePicker
                          placeholder="End Time"
                          onChange={(time) => {
                            setTouched({ ...touched, end_time: true });
                            setFieldValue("end_time", time);
                            handleEndTimeChange(time);
                          }}
                          selectedTime={values?.end_time}
                        />

                        {maxTime && (
                          <Error
                            error={"The Time should be less than Player Time"}
                          />
                        )}

                        {touched.end_time && errors.end_time && (
                          <Error error={errors.end_time} />
                        )}
                      </InputLayout>
                    </div>

                    {/* {console.log("TOUCHED-ARRAY", touched)}
                    {console.log("touvjed", touched.end_time)} */}
                    {console.log("dirty, isvalid", dirty, isValid, errors)}

                    <div className="row g-3 mt-2">
                      {/* <InputLayout style={{ width: "40%" }}>
                        <label htmlFor="positionx">Position X</label>
                        <Field
                          type="text"
                          id="positionx"
                          name="positionx"
                          placeholder="Position X"
                          disabled
                        />
                      </InputLayout>
                      <InputLayout style={{ width: "40%" }}>
                        <label htmlFor="positiony">Position Y</label>
                        <Field
                          type="text"
                          id="positiony"
                          name="positiony"
                          placeholder="Position Y"
                          disabled
                        />
                      </InputLayout> */}
                    </div>
                  </>
                )}
                {/* <div className="mb-3">
                  <label>Widget Type</label>
                  <InputLayout>
                    <SelectDropdown
                      data={existingCollectionData}
                      onChange={(option) => {
                        setAddItemType(option);
                        setActiveTab(option);
                        // setFieldValue("dropDownType", option);
                      }}
                      placeholder="Existing Collection"
                      // isClearable={true}
                      selectedValue={() => {}}
                    />
                  </InputLayout>
                    </div> */}
                {(addItemType === "Description" ||
                  addItemType === "Cart" ||
                  addItemType === "HighLight") && (
                  <div className="d-flex align-items-center  w-100 justify-content-end mt-4">
                    <button
                      className="btn btn-outline-dark mt-0 me-2 w-20"
                      type="button"
                      onClick={() => {
                        handleCancelButton();
                      }}
                    >
                      Cancel
                    </button>
                    {videoEditTable?.id ? (
                      <>
                        <button
                          className="btn btn-primary mt-0 me-2 w-20"
                          type="submit"
                          disabled={
                            !isValid ||
                            hoverItem?.positionx <= 0 ||
                            hoverItem?.positiony <= 0 ||
                            isLoading
                          }
                        >
                          Update
                          {isLoading && (
                            <span
                              className="spinner-border spinner-border-sm ms-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`btn btn-primary mt-0 me-2 w-20`}
                          type="submit"
                          disabled={
                            !dirty ||
                            !isValid ||
                            hoverItem?.positionx <= 0 ||
                            hoverItem?.positiony <= 0 ||
                            maxTime ||
                            isLoading
                          }
                        >
                          Add
                          {isLoading && (
                            <span
                              className="spinner-border spinner-border-sm ms-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {addItemType === "Collection" && (
        <AddCollection
          video_id={id}
          hoverItem={hoverItem}
          playerTime={playerTime}
          videoLink={videoLink}
          setModalState={setModalState}
          getCollectionDetails={() => {
            getCollectionDetails();
          }}
          getVideopreviewDataById={() => {
            getVideopreviewDataById();
          }}
          videoEditTable={videoEditTable}
          collectionData={collectionData}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
export default AddPreview;
