const itemData = [
    {
        name: 'Shirt',
        value: 'shirt'
    },
    {
        name: 'Pants',
        value: 'pants'
    },
    {
        name: 'Jacket',
        value: 'jacket'
    },
    {
        name: 'Coat',
        value: 'coat'
    },
    {
        name: 'Dress',
        value: 'dress'
    },
    {
        name: 'Skirt',
        value: 'skirt'
    },
    {
        name: 'Jeans',
        value: 'jeans'
    },
    {
        name: 'Shorts',
        value: 'shorts'
    },
    {
        name: 'T-shirt',
        value: 't-shirt'
    },
    {
        name: 'Blouse',
        value: 'blouse'
    },
    {
        name: 'Sweater',
        value: 'sweater'
    },
    {
        name: 'Cardigan',
        value: 'cardigan'
    },
    {
        name: 'Blazer',
        value: 'blazer'
    },
    {
        name: 'Suit',
        value: 'suit'
    },
    {
        name: 'Tie',
        value: 'tie'
    },
    {
        name: 'Scarf',
        value: 'scarf'
    },
    {
        name: 'Hat',
        value: 'hat'
    },
    {
        name: 'Cap',
        value: 'cap'
    },
    {
        name: 'Gloves',
        value: 'gloves'
    },
    {
        name: 'Socks',
        value: 'socks'
    },
    {
        name: 'Shoes',
        value: 'shoes'
    },
    {
        name: 'Boots',
        value: 'boots'
    },
    {
        name: 'Sandals',
        value: 'sandals'
    },
    {
        name: 'Sneakers',
        value: 'sneakers'
    },
    {
        name: 'Heels',
        value: 'heels'
    },
    {
        name: 'Flats',
        value: 'flats'
    },
    {
        name: 'Loafers',
        value: 'loafers'
    },
    {
        name: 'Belt',
        value: 'belt'
    },
    {
        name: 'Bag',
        value: 'bag'
    },
    {
        name: 'Backpack',
        value: 'backpack'
    },
    {
        name: 'Handbag',
        value: 'handbag'
    },
    {
        name: 'Clutch',
        value: 'clutch'
    },
    {
        name: 'Wallet',
        value: 'wallet'
    },
    {
        name: 'Umbrella',
        value: 'umbrella'
    },
    {
        name: 'Watch',
        value: 'watch'
    },
    {
        name: 'Earrings',
        value: 'earrings'
    },
    {
        name: 'Necklace',
        value: 'necklace'
    },
    {
        name: 'Bracelet',
        value: 'bracelet'
    },
    {
        name: 'Ring',
        value: 'ring'
    },
    {
        name: 'Anklet',
        value: 'anklet'
    },
    {
        name: 'Cufflinks',
        value: 'cufflinks'
    },
    {
        name: 'Brooch',
        value: 'brooch'
    },
    {
        name: 'Shawl',
        value: 'shawl'
    },
    {
        name: 'Poncho',
        value: 'poncho'
    },
    {
        name: 'Sweatshirt',
        value: 'sweatshirt'
    },
    {
        name: 'Hoodie',
        value: 'hoodie'
    },
    {
        name: 'Pajamas',
        value: 'pajamas'
    },
    {
        name: 'Robe',
        value: 'robe'
    },
    {
        name: 'Slippers',
        value: 'slippers'
    },
    {
        name: 'Nightgown',
        value: 'nightgown'
    },
    {
        name: 'Lingerie',
        value: 'lingerie'
    },
    {
        name: 'Swimsuit',
        value: 'swimsuit'
    },
    {
        name: 'Bikini',
        value: 'bikini'
    },
    {
        name: 'Trunks',
        value: 'trunks'
    },
    {
        name: 'Gown',
        value: 'gown'
    },
    {
        name: 'Tuxedo',
        value: 'tuxedo'
    },
    {
        name: 'Overalls',
        value: 'overalls'
    },
    {
        name: 'Jumpsuit',
        value: 'jumpsuit'
    },
    {
        name: 'Romper',
        value: 'romper'
    },
    {
        name: 'Bodysuit',
        value: 'bodysuit'
    },
    {
        name: 'Vest',
        value: 'vest'
    },
    {
        name: 'Sarong',
        value: 'sarong'
    },
    {
        name: 'Kaftan',
        value: 'kaftan'
    },
    {
        name: 'Kimono',
        value: 'kimono'
    },
    {
        name: 'Turban',
        value: 'turban'
    },
    {
        name: 'Beret',
        value: 'beret'
    },
    {
        name: 'Beanie',
        value: 'beanie'
    },
    {
        name: 'Bandana',
        value: 'bandana'
    },
    {
        name: 'Glasses',
        value: 'glasses'
    },
    {
        name: 'Sunglasses',
        value: 'sunglasses'
    },
    {
        name: 'Headband',
        value: 'headband'
    },
    {
        name: 'Hair Clip',
        value: 'hair clip'
    },
    {
        name: 'Hair Tie',
        value: 'hair tie'
    },
    {
        name: 'Belt Buckle',
        value: 'belt buckle'
    },
    {
        name: 'Tie Clip',
        value: 'tie clip'
    },
    {
        name: 'Pocket Square',
        value: 'pocket square'
    },
    {
        name: 'Suspenders',
        value: 'suspenders'
    },
    {
        name: 'Leggings',
        value: 'leggings'
    },
    {
        name: 'Tights',
        value: 'tights'
    },
    {
        name: 'Stockings',
        value: 'stockings'
    },
    {
        name: 'Earmuffs',
        value: 'earmuffs'
    },
    {
        name: 'Mitten',
        value: 'mitten'
    },
    {
        name: 'Fascinator',
        value: 'fascinator'
    },
    {
        name: 'Garter',
        value: 'garter'
    },
    {
        name: 'Corset',
        value: 'corset'
    },
    {
        name: 'Petticoat',
        value: 'petticoat'
    },
    {
        name: 'Cummerbund',
        value: 'cummerbund'
    },
    {
        name: 'Necktie',
        value: 'necktie'
    },
    {
        name: 'Bow Tie',
        value: 'bow tie'
    },
    {
        name: 'Ascot',
        value: 'ascot'
    },
    {
        name: 'Kerchief',
        value: 'kerchief'
    },
    {
        name: 'Pantyhose',
        value: 'pantyhose'
    },
    {
        name: 'Parka',
        value: 'parka'
    },
    {
        name: 'Windbreaker',
        value: 'windbreaker'
    },
    {
        name: 'Raincoat',
        value: 'raincoat'
    },
    {
        name: 'Trench Coat',
        value: 'trench coat'
    },
    {
        name: 'Pea Coat',
        value: 'pea coat'
    },
    {
        name: 'Anorak',
        value: 'anorak'
    },
    {
        name: 'Duffel Coat',
        value: 'duffel coat'
    },
    {
        name: 'Cape',
        value: 'cape'
    },
    {
        name: 'Cloak',
        value: 'cloak'
    },
    {
        name: 'Hooded Jacket',
        value: 'hooded jacket'
    },
    {
        name: 'Puffer Jacket',
        value: 'puffer jacket'
    },
    {
        name: 'Down Jacket',
        value: 'down jacket'
    },
    {
        name: 'Sports Bra',
        value: 'sports bra'
    },
    {
        name: 'Gym Shorts',
        value: 'gym shorts'
    },
    {
        name: 'Track Pants',
        value: 'track pants'
    },
    {
        name: 'Jersey',
        value: 'jersey'
    },
    {
        name: 'Baseball Cap',
        value: 'baseball cap'
    },
    {
        name: 'Visor',
        value: 'visor'
    },
    {
        name: 'Sash',
        value: 'sash'
    },
    {
        name: 'Leotard',
        value: 'leotard'
    },
    {
        name: 'Tunic',
        value: 'tunic'
    },
    {
        name: 'Pullover',
        value: 'pullover'
    },
    {
        name: 'Smock',
        value: 'smock'
    },
    {
        name: 'Apron',
        value: 'apron'
    },
    {
        name: 'Uniform',
        value: 'uniform'
    },
    {
        name: 'Costume',
        value: 'costume'
    },
    {
        name: 'Mask',
        value: 'mask'
    },
    {
        name: 'Muffler',
        value: 'muffler'
    },
    {
        name: 'Waistcoat',
        value: 'waistcoat'
    },
    {
        name: 'Luggage & Bags',
        value: 'luggage & bags'
    }
];


export const itemDataDropdown = itemData?.map((item) => {
  return {
    value: item?.value,
    label: item?.name,
  };
});